<template>
<nav class="sidebar" dir="rtl">
  <div class="nav-header">
    <div class="logo-wrap">
      <router-link class="logo-icon" to="/"><img alt="logo-icon" src="../../assets/Comedy_club.png" width="120"></router-link>
      <!-- <router-link class="logo-text" to="/">Comedy_club</router-link> -->
    </div>

    <div class="nav-search">
      <div class="search">
        <i class="material-icons" @click.prevent="SearchData()">search</i>
        <form  @submit.prevent="SearchData()">
        <input type="search" v-model="search" name="search" placeholder="عن ماذا تبحث ؟">
      </form>
      </div>
    </div>
  </div>
  <ul class="nav-categories ul-base">
    <li  v-for="publish in Categories" :key="publish.id">
                <router-link :to="{name:'Category',params:{id:publish.id}}">{{ publish.name_en }}</router-link>
              </li>
  </ul>
  <ul class="social ul-base">
    <li><router-link to="/" class="twitter" target="_blank"><img src="../../assets/zain_gray.png" style="width:120px"/> </router-link></li>
    
  </ul>
  <div class="copyright">
    <span>&copy; 2023 <a href="www.byte.sd" class="link">BYTE</a> </span>
    <span>Powered by <a href="https://sd.zain.com" target="_blank">LOTUS</a></span>
  </div>
</nav><!-- @end nav -->

<!-- header -->
<header >
  <div class="header-inner clearfix" dir="rtl">
    <div class="header-menu">
      <ul class="ul-base">
        <li><router-link to="/" class="twitter" target="_blank"><img src="../../assets/zain_gray.png" style="width:120px"/> </router-link></li>
        <!-- <li><a href="https://www.facebook.com/mediahawkz" class="facebook" target="_blank"><i class="fa fa-facebook"></i></a></li>
        <li><a href="https://plus.google.com/+MediahawkzIndia" class="google-plus" target="_blank"><i class="fa fa-google-plus"></i></a></li>
        <li><a href="http://www.mediahawkz.com/rss" class="rss" target="_blank"><i class="fa fa-rss"></i></a></li> -->
        <template v-if="showHeader">
          <li><router-link to="/unsubscribe"> الغاء الاشتراك</router-link></li>
          <li><a @click.prevent="exitUser()" > تسجيل خروج</a></li>
        </template>
        <template v-else>
          <li><a @click="Fuck()">إشتراك</a></li>
          <li><router-link to="/Login" >تسجيل الدخول</router-link></li>
          
        </template>

        
      </ul>
    </div>
    <div class="header-search">
      <div class="search">
        <i class="material-icons" @click.prevent="SearchData()">search</i>
        <form  @submit.prevent="SearchData()">
        <input type="search" v-model="search" name="search" placeholder="عن ماذا تبحث ؟">
      </form>
      </div>
    </div>
    <div class="header-categories">
      <ul class="ul-base">
        <li class="dropdown">
          <a class="dropdown-toggle" href="#"><span>التصنيفات</span> <i class="material-icons">keyboard_arrow_down</i></a>
          <ul class="dropdown-menu ul-base">
            
              <li  v-for="publish in Categories" :key="publish.id">
                <router-link :to="{name:'Category',params:{id:publish.id}}">{{ publish.name_en }}</router-link>
              </li>
            
            
          </ul>
        </li>
      </ul>
    </div>
    
    <div class="header-logo">
      <router-link to="/"><img alt="logo" src="../../assets/Comedy_club.png"></router-link>
    </div>
    <div class="nav-btn nav-slider">
      <i class="material-icons">menu</i>
    </div>
    
  </div>
</header><!-- @end header -->

<!-- Main content -->
<main role="main"></main><!-- @end main -->

<!-- Footer -->

<div class="overlay"></div>






  <!-- <div class="nav">
    <input type="checkbox" id="nav-check" />
    <div class="nav-header">
      <div class="nav-title">
        <router-link to="/"><img class="logo" src="../../assets/Comedy_club.png" /> </router-link>
        
      </div>
      <img class="zain-logo" src="../../assets/zain_gray.png" />
      <template v-if="showHeader">
         <router-link class="btn header-btn btn-outline-dark ml-auto" to="/unsubscribe" > الغاء الاشتراك</router-link>
      </template>
      <template v-else>
        <router-link to="/Subscribe" class="btn header-btn btn-outline-dark ml-auto">إشترك الان</router-link>
      </template>
    </div>
    <div class="nav-btn">
      <label for="nav-check">
        <span></span>
        <span></span>
        <span></span>
      </label>
    </div>
    <div class="nav-links" dir="rtl">
      <router-link to="/">الرئيسية</router-link>
     
        
      <template v-if="showHeader">
        <a class=""   href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                                        <svg class="svg-icon svg-view" style="margin-right:0px;width:17px;margin-left: 0;display: inherit;" viewBox="0 0 20 20">
							               <path fill="#fff" d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
						                </svg>
                                        {{ $cookie.getCookie("msisdn") }}
                                    </a>
                                    <ul class="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenuSub">
                                        <li>
                                            <a @click.prevent="exitUser()" >تسجيل خروج</a>
                                        </li>
                                    </ul>
      </template>
      <template v-else>
        <a class=""   href="#" role="button" id="dropdownMenuSub" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
          <svg class="svg-icon svg-view" style="margin-right:0px;width:17px;margin-left: 0;display: inherit;" viewBox="0 0 20 20">
						<path fill="#fff" d="M13.962,8.885l-3.736,3.739c-0.086,0.086-0.201,0.13-0.314,0.13S9.686,12.71,9.6,12.624l-3.562-3.56C5.863,8.892,5.863,8.611,6.036,8.438c0.175-0.173,0.454-0.173,0.626,0l3.25,3.247l3.426-3.424c0.173-0.172,0.451-0.172,0.624,0C14.137,8.434,14.137,8.712,13.962,8.885 M18.406,10c0,4.644-3.763,8.406-8.406,8.406S1.594,14.644,1.594,10S5.356,1.594,10,1.594S18.406,5.356,18.406,10 M17.521,10c0-4.148-3.373-7.521-7.521-7.521c-4.148,0-7.521,3.374-7.521,7.521c0,4.147,3.374,7.521,7.521,7.521C14.148,17.521,17.521,14.147,17.521,10"></path>
					</svg>
          الحساب
          </a>
                                    <ul class="dropdown-menu header__nav-menu" aria-labelledby="dropdownMenuSub">
                                        <li>
                                            <router-link to="/Login" >تسجيل الدخول</router-link>
                                        </li>
                                    </ul>
      </template>
       
      
    </div>
  </div> -->
</template>

<script>
import { HTTPDSP } from '@/Api/http-dsp';
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { useCookie } from 'vue-cookie-next';
export default {
    setup() {
      const cookie = useCookie()
      const showHeader = ref(true);

      const Categories = ref([]);


      try {
             HTTP.get(`GetAllCategories.php`).then((res) => {
                Categories.value = res.data.Categories; 
            });
        } catch (err) {
            console.log(err);
        }
      watchEffect(() => {
        if (cookie.isCookieAvailable("msisdn")) {
            HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+cookie.getCookie("msisdn")).then((res) => {
              if (res.data.status == 1 ) {
                return;
              } else {
                cookie.removeCookie("msisdn");
                cookie.removeCookie("Content_id");
                cookie.removeCookie("status");
              }
            });
              showHeader.value = true;
        } else {
          showHeader.value = false;
        }
      }); 
      
    const Fuck = () =>{
      window.open("https://dsplp.sd.zain.com/?p=9619984619", "_self");	
    }
        return { showHeader,Categories ,Fuck};
    },
    mounted() {
      let externalScript = document.createElement('script')
        externalScript.setAttribute('src', '/ComedyZain/assets/js/main.js')
        document.head.appendChild(externalScript)

        let externalScript2 = document.createElement('script')
        externalScript2.setAttribute('src', '/ComedyZain/assets/js/header.js')
        document.head.appendChild(externalScript2)
    },
    methods: {
    exitUser: function () {

      this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
      this.$cookie.removeCookie("msisdn");
      this.$cookie.removeCookie("Content_id");
      this.$cookie.removeCookie("remming_minutes");
      this.$cookie.removeCookie("status");
      this.$cookie.removeCookie("product_code");
      
        this.$router.push("/");
        this.$router.go()
      
    },
    SearchData() {
      this.$router.push({ name: "Search", params: { search: this.search } });
    }
    }
};
</script>

<style lang="scss" scoped>
// Body Colors
$body-txt-color: #fff;
$body-bg-color: #eee;
$selection-color: #b3d4fc;
$link-txt-color: #03a9f4;

// Header
$header-bg-color: #cf0072;

// Social media color
$twitter-color: #00baf0;
$facebook-color: #3b5997;
$google-plus-color: #c43c2c;
$rss-color: #ff6600;

// Dropdown menu
$dd-menu-bg-color: $header-bg-color;

/* -----------------------------------------------------
  Body
-------------------------------------------------------- */
body {
  margin: 0;
  padding: 0;
  color: $body-txt-color;
  font-size: 16px;
  font-family: "Zain" !important;
  background-color: $body-bg-color;
}

*, *::after, *::before {
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  background: $selection-color;
  text-shadow: none;
}

a {
  color: $link-txt-color;
  outline: none;
  text-decoration: none;

  &:hover { text-decoration: underline; }
}

.overlay {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.285);
  z-index: 1001;
}

/* For Typography */
.ul-base {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* -----------------------------------------------------
Clearfix
-------------------------------------------------------- */
.clearfix {
  *zoom: 1;

  &::after {
    content: '';
    display: table;
    line-height: 0;
    clear: both;
  }
}

/* -----------------------------------------------------
   Header
-------------------------------------------------------- */
header {
  display: block;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #403e3e;
  z-index: 1000;

  .header-inner {
    width: 100%;
    height: 64px;
    color: #fff;
    padding: 0 20px;
    background-color: $header-bg-color;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.3);
  }

  .nav-btn {
    cursor: pointer;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    margin: 12px 0;
    user-select: none;

    &.nav-slider { float: right; }
    &.search { display: none; }

    i { vertical-align: middle; }
  }

  .header-logo {
    display: inline-block;
    overflow: hidden;
    float: right;
    padding: 12px 36px 0 5px;

    img {
      display: block;
        max-height: 40px;
        vertical-align: middle;
        width: 120px;
    }
  }

  .header-categories {
    display: block;
    position: relative;
    float: left;
    // border-left: 1px solid rgba(255,255,255,0.157);
  }

  .header-search {
    float: left;
    display: block;
    position: relative;
    width: calc(100% - 800px);
    height: 64px;
    margin: 0 auto;
    padding: 10px 0;
    // border-left: 1px solid rgba(255, 255, 255, 0.157);
    // border-right: 1px solid rgba(255, 255, 255, 0.157);

    .search {
      display: block;
      position: relative;
      width: calc(100% - 35px);
      margin: 0 auto;

      i {
        position: absolute;
        top: 8px;
        left: 10px;
        color: rgba(255, 255, 255, 0.6);
        font-size: 28px;
        cursor: pointer;
      }

      input[type="search"] {
        width: 100%;
        height: 44px;
        padding: 0 15px 0 50px;
        color: rgba(255, 255, 255, 0.8);
        font-size: 16px;
        outline: none;
        border: none;
        border-radius: 2px;
        background-color: rgba(255, 255, 255, 0.2);
      }

      ::-webkit-input-placeholder { color: rgba(255,255,255,0.5); }
      ::-moz-placeholder { color: rgba(255,255,255,0.8); }
      :-moz-placeholder { color: rgba(255,255,255,0.8); }
      :-ms-input-placeholder { color: rgba(255,255,255,0.8); }
    }
  }

  .header-menu {
    float: left;

    ul {
      height: 64px;

      li {
        display: block;
        position: relative;
        float: left;
        margin: 0;
        padding: 0 0 0 12px;
        line-height: 64px;

        a {
          color: #fff;
          font-size: 17px;
          padding: 4px;

          &:hover {
            opacity: 0.6;
            text-decoration: none;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .header-categories { display: none; }

    .header-search { width: calc(100% - 60vw); }
  }

  @media only screen and (max-width: 873px) {
    // .header-search { display: none; }
    .header-menu li a img { width: 79px !important; }
    .header-menu {
      ul li:last-child { display: none; }
    }
  }
  @media only screen and (max-width: 614px) {
    .header-search { display: none; }
    .header-menu {
      ul li:last-child { display: block; }
    }
  }
  @media only screen and (max-width: 510px) {
    // .header-search { display: none; }
    .header-menu {
      ul li:last-child { display: none; }
    }
  }

  @media only screen and (max-width: 480px) {
    .header-inner {
      padding: 0 12px;
      text-align: center;
    }

    .nav-btn { background-color: rgba(0,0,0,0.175); }

    .header-logo {
      float: left;
      padding-left: 0px;
      padding-right: 0px;
    }
    .header-menu ul li{margin-right: 30px;}
     .header-menu li a img { width: 79px !important; }
  }
}
@media only screen and (max-width: 420px) {
    

   
    
    .header-menu ul li{margin-right: 0px !important;}
   
}

/* -----------------------------------------------------
   Nav
-------------------------------------------------------- */
nav {
  overflow-y: auto;
  position: fixed;
  top: 0px;
  left: -70vw;
  width: 70vw;
  height: 100vh;
  color: #fff;
  background-color: #f9f9f9;
  transition: all 0.3s ease;
  opacity: 0.9;
  z-index: 1002;

  .nav-header {
    display: block;
    background-color: $header-bg-color;

    .logo-wrap {
      padding: 1.7em 1em 0;

      .logo-icon {
        display: inline-block;
        text-decoration: none;

        img {
          display: block;
          border-radius: 0;
            width: 150px;
        }
      }

      .logo-text {
        display: inline-block;
        color: #fff;
        font-size: 1.2em;
        vertical-align: 12px;
        margin-left: 8px;
        text-decoration: none;
      }
    }

    .nav-search {
      position: relative;
      height: 40px;

      .search {
        display: block;
        position: relative;

        i {
          position: absolute;
          top: 8px;
          right: .7em;
          color: rgba(255, 255, 255, 0.6);
          font-size: 24px;
          cursor: pointer;
        }

        input[type="search"] {
          width: 100%;
          height: 40px;
          color: rgba(255, 255, 255, 0.8);
          font-size: 15px;
          padding: 0 48px 0 20px;
          outline: none;
          border: none;
          background-color: rgba(255, 255, 255, 0.2);
        }

        ::-webkit-input-placeholder { color: rgba(255,255,255,0.5); }
        ::-moz-placeholder { color: rgba(255,255,255,0.8); }
        :-moz-placeholder { color: rgba(255,255,255,0.8); }
        :-ms-input-placeholder { color: rgba(255,255,255,0.8); }
      }
    }
  }

  .nav-categories {
    li {
      display: block;
      position: relative;

      a {
        display: block;
        width: 100%;
        font-size: 1rem;
        color: #5c5c5c;
        padding: .625em 1.3em;
        text-decoration: none;

        &:hover { background-color: rgba(0,0,0,0.1); }
      }
    }
  }

  &.open {
    left: 0px;
    box-shadow: 4px 4px 20px rgba(0,0,0,0.275);
    opacity: 1;
  }

  .nav-separator {
    width: 100%;
    height: 1px;
    margin: 7px 0;
    background-color: rgba(0,0,0,0.175);
  }

  .nav-link-title {
    display: block;
    color: $header-bg-color;
    font-size: 1rem;
    padding: .5em 1.3em;
  }

  .social {
    display: none;
// margin: 4px 0;
//   margin-bottom: 4px;
position: absolute;
justify-content: center;
left: 0;
right: 0;
margin-bottom: 2vh;

    li {
      display: inline-block;
      position: relative;
      width: 100%;
      height: 28px;
      margin: 0;
      padding: 0;
      text-align: center;
      line-height: 28px;
      flex-direction: row;
      border-right: 1px solid rgba(0,0,0,0.175);

      &:last-child { border-right: 0; }

      a {
        display: block;
        color: #5c5c5c;
        font-size: 15px;
        padding: 2px;
        text-decoration: none;

        &.twitter {
          &:hover { color: #00baf0; }
        }
        &.facebook {
          &:hover { color: #3b5997; }
        }
        &.google-plus {
          &:hover { color: #c43c2c; }
        }
        &.rss {
          &:hover { color: #ff6600; }
        }
      }
    }
  }

  .copyright {
    display: flex;
color: #000;
text-align: center;
padding: 0.5em 0 1em;
position: absolute;
bottom: 5vh;
right: 0;
left: 0;
flex-direction: column;

    span {
      display: block;
width: 100%;
font-size: 11px;
margin-bottom: 4px;
color: #000;
    }
  }
}

@media(min-width: 786px){
 nav{
  width:40vw;
  left: -40vw;
 }
}
/* -----------------------------------------------------
   Dropdown Menu
-------------------------------------------------------- */
li.dropdown {
  display: inline-block;
  position: relative;

  a {
    display: block;
    height: 64px;
    padding: 0 35px;
    color: #fff;
    font-size: 17px;
    line-height: 64px;
    text-decoration: none;

    i { vertical-align: middle; }
  }

  .dropdown-menu {
    display: none;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0px;
    min-width: 240px;
    margin: 2px 0;
    padding: 5px 0;
    text-align: left;
    border-radius: 2px;
    background-color: $dd-menu-bg-color;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;

    li {
      display: block;

      a {
        display: block;
        height: 40px;
        font-size: 1em;
        line-height: 40px;
        text-decoration: none;
        float: right;
      }
    }
  }

  &.open .dropdown-menu { display: block; }
}
</style>