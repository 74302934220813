<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="footer__content">
            <div class="footer__social">
              <img style="width: 100px" src="../../assets/zain_gray.png" alt="" />
            </div>
            <span>
              <small class="footer__copyright">Powered by LOTUS</small>
              <small class="footer__social">Designed by BYTE © 2023</small>
            </span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style>
</style>