<template>
  <div class="lds-ripple"><div></div><div></div></div>
</template>

<script>
export default {

}
</script>

<style>
.lds-ripple {
display: block;
position: relative;
width: 100px;
height: 100px;
right: -47vw;
top: 40vh;
}
@media(max-width:786px) {
  .lds-ripple {
    right: -40vw;
  }
  
}
.lds-ripple div {
  position: absolute;
border: 4px solid #fff;
  border-top-color: rgb(255, 255, 255);
  border-right-color: rgb(255, 255, 255);
  border-bottom-color: rgb(255, 255, 255);
  border-left-color: rgb(255, 255, 255);
border-color: #CF0072 #CF0072 #CF0072 #CF0072;
opacity: 1;
border-radius: 35%;
-webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
animation: lds-ripple 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

</style>